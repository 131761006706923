import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';
import type { OrderAlarm } from '@/server/transformers/shop/orderAlarmTransformer';
import { DialogResponses, useDialogStore } from '@/stores/useDialogStore';
import { handleLoadingError } from '~/utils/handleLoadingError';

export async function useOrderAlarmDialog() {
  const dialogStore = useDialogStore();
  const site = useSiteIdent();

  async function onSubmit(status: boolean, orderAlarm?: OrderAlarm) {
    let response = '';
    try {
      if (orderAlarm) {
        response = await useSecureSessionPost<string>(
          `/api/${site}/shop/orderAlarm/item/toggleStatus`,
          {
            orderAlarmId: orderAlarm.id,
            orderAlarmStatus: !status,
          },
        );
      } else {
        response = await useSecureSessionPost<string>(
          `/api/${site}/shop/orderAlarm/toggleStatus`,
          {
            orderAlarmStatus: !status,
          },
        );
      }
      if (response) {
        dialogStore.closeDialog(DialogResponses.SUCCESS);
      } else {
        dialogStore.closeDialog(DialogResponses.CANCEL);
      }
    } catch (e: any) {
      dialogStore.closeDialog(DialogResponses.CANCEL);
      handleLoadingError(e);
    }
  }
  return {
    onSubmit,
  };
}
